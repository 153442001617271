import React from "react";
import Layout from "../../components/mini/layout";
import SEO from "../../components/mini/seo";
import FlexCard from "../../components/mini/flexgiftcard";
import GiftCardBackground from "../../components/giftcardbackground";
import settings from "../../../settings";


const topbanner = `${settings.IMAGES_BASE_URL}/v2/images/mini/specialoffers/desktop/MINI-specials-banner.jpg`;
const miniBanner = `${settings.IMAGES_BASE_URL}/v2/images/mini/specialoffers/desktop/MINI-banner.jpg`;

const SpecialOffers = () => {
  const today = new Date();
  const novPromotions =
    today >= new Date(2023,10,1) &&
    today < new Date(2024,0,1);
  const getFirstCard = () => {
    if (novPromotions) {
      return (
        <>
          <FlexCard
            rowType="row"
            image={miniBanner}
            imageAlt="A MINI GIFT WITH MAXIMUM FUN"
            header="A MINI GIFT WITH MAXIMUM FUN"
            subheader="20% OFF A GIFT CARD"
            text="The holidays are Go, Go, Go this year. Get 20% off a gift card, good for any MINI Driving Experience class."
            btnText="Call 888-345-4269 to book now"
            btnStyle="btn-black"
            phone="tel:888-345-4269"
          >
            <p>
              Call <a className="phone-number" href="tel:888-345-4269">888-345-4269</a> to book your class today. Use promo code <span className="bold">23RBMINIHGC20</span>. Must purchase gift card by 12/31/23.
            </p>
          </FlexCard>
        </>
        );
    } else {
      return <></>;
    }
  };
  return (
    <Layout>
      <SEO
        title="MINI Driving Experience USA | MINI Driving School"
        description="Over 50 years of motorsport legacy goes into the MINI Driving Experience in the form of Group Events, Stunt Driving School and autocross and track school."
      />
      <div className="giftcardpage">
        <GiftCardBackground
          imageDesktop={topbanner}
          imageMobile={topbanner}
          imageAlt="BMW Gift Card Top Banner"
        />

        <section className="drivingschool">
          <div className="container">
            <div className="giftcard__header">
              <h2>SPECIAL OFFERS</h2>
            </div>
          </div>
          {getFirstCard()}
          <br />
        </section>
      </div>
    </Layout>
  );
};

export default SpecialOffers;
